<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{$t('buttons.modules')}}</h1>
    </div>
    <h2>Navigatie</h2>

    <b-form-checkbox  v-model="settings.ShowInvoicePurchaseNav">{{ $t("modules.ShowInvoicePurchaseNav") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowInvoiceSaleNav">{{ $t("modules.ShowInvoiceSaleNav") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowPlanningNav">{{ $t("modules.ShowPlanningNav") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowPlanningPerWeekNav">{{ $t("modules.ShowPlanningPerWeekNav") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowSearchNav">{{ $t("modules.ShowSearchNav") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowTodoNav">{{ $t("modules.ShowTodoNav") }}</b-form-checkbox>

    <h2>Knoppen</h2>

    <b-form-checkbox  v-model="settings.ShowEmailButton">{{ $t("modules.ShowEmailButton") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowInvoicePurchaseButton">{{ $t("modules.ShowInvoicePurchaseButton") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowInvoiceSaleButton"> {{ $t("modules.ShowInvoiceSaleButton") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableBlacklist">{{ $t("modules.EnableBlacklist") }}</b-form-checkbox>

    <h2>Opties</h2>
    <b-form-checkbox  v-model="settings.InvoiceByHourcode">{{ $t("modules.InvoiceByHourcode") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.AddCreditInvoice">{{ $t("modules.AddCreditInvoice") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.PMHistory">{{ $t("modules.PMHistory") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.NewChat">{{ $t("modules.NewChat") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.SendInvoiceOnChecked">{{ $t("modules.SendInvoiceOnChecked") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.SendInvoiceSaleOnChecked">{{ $t("modules.SendInvoiceSaleOnChecked") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableHourPlanning">{{ $t("modules.EnableHourPlanning") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableVacationPlanning">{{ $t("modules.EnableVacationPlanning") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableUsageInvoices">{{ $t("modules.EnableUsageInvoices") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableEmployees">{{ $t("modules.EnableEmployees") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableNewsletter">{{ $t("modules.EnableNewsletter") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.RegisterWorkhours">{{ $t("modules.RegisterWorkhours") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.SignWorkhours">{{ $t("modules.SignWorkhours") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableWorkTimer">{{ $t("modules.EnableWorkTimer") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.EnableScheduler">{{ $t("modules.EnableSchedule") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.SignContract">{{ $t("modules.SignContract") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.ShowBSN">{{ $t("modules.ShowBSN") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.KVKAPI">{{ $t("modules.KVKAPI") }}</b-form-checkbox>
    <b-form-checkbox  v-model="settings.useFinqle">{{ $t("modules.useFinqle") }}</b-form-checkbox>

    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>       
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'modules-screen',
  data() {
    return {
    }
  },

  computed: {

    ...mapState([
      'settings',
    ]),
    ...mapGetters([
      'server_url',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),

    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            ShowInvoicePurchaseNav: this.settings.ShowInvoicePurchaseNav,
            ShowInvoiceSaleNav: this.settings.ShowInvoiceSaleNav,
            ShowPlanningNav: this.settings.ShowPlanningNav,
            ShowPlanningPerWeekNav: this.settings.ShowPlanningPerWeekNav,
            ShowSearchNav:this.settings.ShowSearchNav,
            ShowTodoNav:this.settings.ShowTodoNav,
            ShowEmailButton:this.settings.ShowEmailButton,
            ShowInvoicePurchaseButton:this.settings.ShowInvoicePurchaseButton,
            ShowInvoiceSaleButton:this.settings.ShowInvoiceSaleButton,
            InvoiceByHourcode:this.settings.InvoiceByHourcode,
            AddCreditInvoice:this.settings.AddCreditInvoice,
            PMHistory: this.settings.PMHistory,
            NewChat:this.settings.NewChat,
            SendInvoiceOnChecked:this.settings.SendInvoiceOnChecked,
            SendInvoiceSaleOnChecked:this.settings.SendInvoiceSaleOnChecked,
            EnableBlacklist:this.settings.EnableBlacklist,
            EnableHourPlanning:this.settings.EnableHourPlanning,
            EnableVacationPlanning:this.settings.EnableVacationPlanning,
            EnableUsageInvoices:this.settings.EnableUsageInvoices,
            EnableEmployees:this.settings.EnableEmployees,
            EnableNewsletter:this.settings.EnableNewsletter,
            RegisterWorkhours:this.settings.RegisterWorkhours,
            SignWorkhours:this.settings.SignWorkhours,
            EnableWorkTimer: this.settings.EnableWorkTimer,
            EnableScheduler:this.settings.EnableScheduler,
            SignContract: this.settings.SignContract,
            ShowBSN: this.settings.ShowBSN,
            useFinqle: this.settings.useFinqle,
            KVKAPI: this.settings.KVKAPI

          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },

}
</script>